.table {
    order: 3;
    width: 48%;
    height: 50%;
    min-height: 10rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin: 1rem 0;
    background-color: antiquewhite;
    border-radius: 2rem;
}
.table .noCardMessage {
    font-size: 2.5em;
    font-style: italic;
}

