.game-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /*height: 100vh;*/
  height: 100%;
}

.bottom-row {
  order: 5;
  display: flex;
  justify-content: space-between;
  width: 100%;
}