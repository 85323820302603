.score {
    order: 3;
    width: 20%;
    /*height: 50%;*/
    min-height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin: 1rem 0;
    background-color: antiquewhite;
    border-radius: 2rem;
}

.score table {
    width: 90%;
    text-align: center;
}

table, th, td {
    border: 0.1rem solid gray;
}

/*table,th,td{border:1px solid #cccccc}*/


tbody tr:nth-child(odd) {
    background-color: rgba(166, 166, 166, 0.34);
}

tbody tr:nth-child(even) {
    background-color: #ffffff;
}

/*.score .noCardMessage {*/
/*    font-size: 2.5em;*/
/*    font-style: italic;*/
/*}*/

