.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #0077cc;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.button[disabled] {
    opacity: 0.5; /* Уменьшаем прозрачность */
    cursor: not-allowed; /* Меняем курсор на "запрещенный" */
    pointer-events: none; /* Отключаем события мыши */
}

.input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
}
