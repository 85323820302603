.actions {
    order: 1;
    width: 20%;
    min-height: 10rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 1rem 0;
    background-color: antiquewhite;
    border-radius: 2rem;
    flex-direction: column;
}

/*.score table {*/
/*    width: 90%;*/
/*    text-align: center;*/
/*}*/

