.card {
    height: 8rem;
    line-height: 8rem;
    width: 5rem;
    text-align: center;
    display: inline-block;
    margin: 1rem;
    border-radius: 1rem;
    user-select: none;
}

.number {
    font-size: 5em;
}

.small {
    height: 6rem;
    line-height: 6rem;
    width: 3.5rem;
}

.small > .number {
    font-size: 2.5em;
}

.isClickable {
    cursor: pointer;
}