.player-top {
    order: 1;
    width: 100%;
    height: 25%;
    align-self: flex-start;
    text-align: center;
}

.player-left {
    order: 2;
    width: 25%;
    height: 25%;
    text-align: center;
}

.player-right {
    order: 4;
    width: 25%;
    height: 25%;
    text-align: center;
}

.player-bottom {
    order: 2;
    width: 60%;
    height: 25%;
    align-self: center;
    text-align: center;
}

.deckCardShirt {
    /* рубашка карты */
    height: 8rem;
    width: auto;
    padding: 0 1rem;
    margin: -2.4rem 0;
}

.blitzDeck > div {
    margin: 0 1rem;
}
.blitzDeck > p {
    margin: 0.1rem 0.5rem;
}

.deckCardShirt.small {
    height: 6rem;
}

.cardsOnHands span {
    display: inline-block;
    align-items: center;
    margin: 0 1rem;
    background-color: #ccc;
    border-radius: 0.5rem;
    height: 10rem;
    vertical-align: middle;
}

.blitzOnTable {
    /* 3 карты из  колоды блиц */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.deckCards {
    /* 3 карты из основной деки */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    display: inline-flex!important;
}